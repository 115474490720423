<template>
  <div class="author">
    <!--suppress HtmlUnknownTarget -->
    <a v-if="isRoutable()" :href="getRoute()">
      <!--suppress HtmlUnknownTarget -->
      <img class="avatar" :src="getAvatar()" alt="Аватар">
    </a>
    <!--suppress HtmlUnknownTarget -->
    <img v-if="!isRoutable()" class="avatar" :src="getAvatar()" alt="Аватар">
    <div class="info">
      <span class="name" v-if="!isRoutable()">
        {{ getName() }}
      </span>
      <a v-if="isRoutable()" :href="getRoute()" class="name">
        {{ getName() }}
      </a>
      <span class="description" v-if="description">
        {{ description }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Author',
  props: [
    'info',
    'description',
    'clickable',
  ],

  methods: {
    getAvatar() {
      if(!this.info || !this.info.image) {
        return '/images/generics/avatar-anonymous.svg'
      }

      return `${this.$root.globalParams.developerBaseURI}${this.info.image}`
    },

    getName() {
      if(!this.info) {
        return 'Аноним'
      }

      return this.info.name
    },

    isRoutable() {
      const result = this.clickable && this.info
      return result
    },

    getRoute() {
      if(!this.clickable || !this.info) {
        return {}
      }

      /*
      const route = {
        name: 'content-reader',
        params: {
          type: 'u',
          slug: this.info.name,
        },
      }
       */
      const route = `/u/${encodeURIComponent(this.info.name)}`
      return route
    }
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  .author {
    display: flex;
    align-items: flex-start;
  }

  .avatar {
    --size: 70px;
    position: relative;
    border: 2px solid var(--type-color);
    width: var(--size);
    height: var(--size);
    display: block;

    .mini-avatar & {
      --size: 50px;
    }

    .small & {
      --size: 35px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1em;
    overflow-x: hidden;

    .dark & {
      color: @color-block-background;
    }

    .small & {
      font-size: 11pt;
      margin-left: 0.5em;
    }
  }

  .name {
    font-weight: 500;
    overflow-wrap: break-word;
    max-width: 100%;
  }

  .description {
    opacity: 0.6;
  }

  a {
    color: @color-text;
  }
</style>
