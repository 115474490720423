<template>
  <router-link
    :to="link"
    class="card"
    :class="[
      `type-${type}`,
      `size-${size}`,
      { dark: $root.isDark(cardData.color) },
    ]"
    :style="`--color: ${cssColor}; --image: url('${imageURL}'); --mobile-order: ${cardData.mobileOrder}`"
  >
    <div class="image">
      <div class="gradient-overlay"></div>
    </div>
    <div class="content">
      <div class="type-wrapper">
        <span class="type">
          {{ $root.$data.contentTypes[type].displayName }}
        </span>
        <Stats
          class="wide-stats"
          :views="isInEditor ? '…' : cardData.views"
          :rating="isInEditor ? '…' : cardData.rating"
          :comments="isInEditor ? '…' : cardData.comments"
        />
      </div>
      <span class="title">
        {{ cardData.title }}
      </span>
      <span class="description" v-if="cardData.description">
        {{ cardData.description }}
      </span>
      <Author
        v-if="(cardData.type !== 'user' && cardData.type !== 'question')"
        :info="cardData.author"
        :description="authorDescription"
        class="small"
        :class="{ dark: $root.isDark(cardData.color) }"
      />
      <Stats
        class="non-wide-stats"
        :class="`size-${size}`"
        :views="isInEditor ? '…' : cardData.views"
        :rating="isInEditor ? '…' : cardData.rating"
        :comments="isInEditor ? '…' : cardData.comments"
      />
    </div>
  </router-link>
</template>

<script>
import Author from '@/components/Shared/Author'
import Stats from '@/components/Shared/Cards/Stats'

export default {
  name: 'ContentCard',
  components: { Stats, Author },
  props: ['cardData', 'isInEditor', 'cardType'],

  computed: {
    link() {
      if(this.isInEditor) {
        return { name: 'home' }
      }

      if(this.cardData.type === 'user') {
        return {
          name: 'user-profile',
          params: { slug: this.cardData.title },
        }
      }

      return {
        name: `${this.cardData.type}-content-reader`,
        params: { slug: this.cardData.slug },
      }
    },

    size() {
      if(this.cardData.size) {
        return this.cardData.size
      }

      if(this.cardData.type === 'question' || this.cardData.type === 'user') {
        return 'small'
      }

      return 'high'
    },

    cssColor() {
      return `${this.cardData.color.red * 100}%,${this.cardData.color.green * 100}%,${this.cardData.color.blue * 100}%`
    },

    imageURL() {
      const image = this.isInEditor ? this.cardData.cover : this.cardData.image

      if(image === null) {
        return `/images/cards-generics/${this.type}.svg`
      }

      if(image.startsWith('/public/images/')) {
        return `${this.$root.globalParams.developerBaseURI}${image}`
      }

      const uuidPath = `${this.$root.globalParams.developerBaseURI}/user-content/content/${image}/orig.jpg`
      return uuidPath
    },

    type() {
      if(this.isInEditor) {
        return this.cardType
      }

      return this.cardData.type
    },

    authorDescription() {
      switch(this.type) {
        case 'article':
          return 'Автор статьи'

        case 'discussion':
          return 'Инициатор дискуссии'

        case 'event':
          return 'Сообщил о событии'
      }

      return 'Автор'
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../../../card-shared.less';

  .text-contrast-background() {
    box-shadow: 0 0 0 5px var(--text-contrast-background-color);
    background-color: var(--text-contrast-background-color);
  }

  .card {
    background-color: rgb(var(--color));
    position: relative;

    --text-contrast-background-color: hsla(0, 0%, 100%, 0.35);

    &.dark {
      --text-contrast-background-color: hsla(0, 0%, 0%, 0.35);
    }

    @media @desktop {
      &.size-wide {
        flex-direction: row-reverse;
        --gradient-direction: to left;
      }
    }

    @media @tablet-m {
      order: var(--mobile-order);
    }
  }

  .content {
    padding: 0 @s-margin @s-margin;
    flex-shrink: 0;
    max-width: @card-width;

    justify-content: flex-end;

    @media @desktop {
      .size-wide & {
        position: relative;
        padding: @s-margin 0 @s-margin @s-margin;
      }
    }

    .overlaying-content() {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
    .size-small & {
      .overlaying-content();
    }

    @media @tablet-m {
      .overlaying-content();
    }
  }

  .type-wrapper {
    @sticker-size: @xxs-margin;

    position: absolute;
    left: -@sticker-size;
    top: @sticker-size * 3;

    display: flex;
    align-items: center;

    .type {
      color: @color-block-background;
      padding: 0.25em 0.75em 0.3em;
      font-size: 11pt;
      font-weight: 600;
      background-color: var(--type-color);

      &::before {
        content: '';
        width: 0;
        height: 0;
        border-top-width: @sticker-size;
        border-top-style: solid;
        border-left: @sticker-size solid transparent;
        position: absolute;
        left: 0;
        bottom: -@sticker-size;
        border-top-color: var(--type-color);
        filter: brightness(0.9);
      }
    }

    .stats {
      margin-left: 1em;
      margin-bottom: unset;
      margin-top: unset;
    }
  }

  .stats-joined-with-author() {
    position: absolute;
    top: 40px;
    left: @s-margin;
    margin-bottom: @s-margin;
    margin-right: @s-margin;
  }

  .type-article,
  .type-discussion,
  .type-event {
    @media @tablet-m {
      .stats {
        .stats-joined-with-author();
      }
    }

    &.size-small .stats {
      .stats-joined-with-author();
    }
  }

  .title {
    --line-height: 23px;
    --max-lines: 4;
    font-family: @font-set-header;
    font-weight: 400;
    font-size: 15pt;
    line-height: var(--line-height);
    max-height: calc(var(--line-height) * var(--max-lines));
    overflow: hidden;
    max-width: 100%;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);

    .size-wide & {
      --max-lines: 3;

      @media @tablet-m {
        --max-lines: 2;
      }
    }

    .type-user &,
    .size-small {
      --max-lines: 2;
    }

    .size-small & {
      .text-contrast-background();
    }

    @media @tablet-m {
      --max-lines: 2;
      .text-contrast-background();
    }
  }

  .description {
    --line-height: 16px;
    --max-lines: 3;
    line-height: var(--line-height);
    max-height: calc(var(--line-height) * var(--max-lines));
    margin-top: @xxs-margin;
    overflow: hidden;
    font-size: 11pt;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);

    .type-user &,
    .size-small & {
      --max-lines: 2;
    }

    .type-article.size-small &,
    .type-discussion.size-small &,
    .type-event.size-small & {
      display: none;
    }

    @media @desktop {
      .size-wide & {
        --max-lines: 2;
      }
    }

    @media @tablet-m {
      display: none;
      --max-lines: 2;
      .text-contrast-background();
      margin-top: 10px;

      .type-user & {
        display: unset;
      }
    }

    .size-small & {
      .text-contrast-background();
      margin-top: 10px;
    }
  }

  .stats {
    margin-bottom: -@xxs-margin;
    margin-top: @xxs-margin;

    &.wide-stats {
      display: none;

      @media @desktop {
        .size-wide & {
          display: flex;
        }
      }
    }

    @media @desktop {
      .size-wide &.non-wide-stats {
        display: none;
      }
    }

    @media @tablet-m {
      display: none;
    }

    .size-small & {
      .text-contrast-background();
      margin-top: 10px;
    }
  }

  .author {
    margin-top: @xxs-margin * 2;

    .size-small & {
      .text-contrast-background();
    }

    @media @tablet-m {
      .text-contrast-background();
    }
  }
</style>
